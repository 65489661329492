.under-construction {
    height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.under-construction img {
    width: 200px;
}

.under-construction h1 {
    font-size: 1.6rem;
    margin: 1rem;
    font-weight: 300;
    text-align: center;
}

.under-construction h1 span {
    font-weight: 900;
}

.under-construction p {
    text-align: center;
    padding: 0.5rem;
}

.under-construction .contact-icons {
    margin: 1rem;
}

.under-construction .contact-icons i {
    margin: 2rem;
    color: #000;
    font-size: 2.5rem;
}