.admin-order-list {
    padding: 10vh;
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-order-list .item {
    position: relative;
    width: 80%;
    height: 80px;
    margin: .2rem 0;
    padding: 1rem;
    padding-right: 100px;
    background: rgba(var(--rgb-light-static),0.1);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.admin-order-list .item.add {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
}

.admin-order-list .item.add i {
    font-size: 2rem;
    margin: 0 1rem;
}
.admin-order-list .item.add i,
.admin-order-list .item.add h1 {
    color: var(--color-dark);
}

.admin-order-list .item.add:hover i,
.admin-order-list .item.add:hover h1 {
    color: var(--color-palette-3);
}

/* Search */

.admin-order-list .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-order-list .search-container .search-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-order-list .search-container .search-bar i {
    height: 40px;
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-static);
    color: var(--color-dark-static);
    border: 1px solid var(--color-dark-static);
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.admin-order-list .search-container .search-bar input {
    height: 40px;
    width: 95%;
    padding: 1rem;
    font-size: 19px;
    border: 1px solid var(--color-dark-static);
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Items */

.admin-order-list .item .section {
    margin: 0 .5rem;
    font-style: italic;
}

.admin-order-list .item .section.options {
    position: absolute;
    right: 1rem;
    padding: .5rem 1rem;
}

@media only screen and (max-width: 1210px) {
    .admin-order-list {
        padding: 10vh 1rem;
    }
    .admin-order-list .item {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        height: unset;
    }
    .admin-order-list .item .vertical-rule {
        display: none;
    }
    .admin-order-list .item p {
        margin: .2rem 0;
    }
    .admin-order-list .item .section.options {
        position: relative;
        right: unset;
        left: 50%;
        display: flex;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
    }
}

.admin-order-list .item .section.options i {
    margin: 0 .7rem;
    font-size: 1.6rem;
    cursor: pointer;
}

.admin-order-list .item .section.options i:hover {
    color: var(--color-palette-3);
}

/* delete modal */

/* .admin-order-list .delete-modal.hidden,
.admin-order-list .modal-overlay.hidden {
    display: none;
}

.admin-order-list .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(var(--rgb-dark-static),0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 98;
} */

.admin-order-list .delete-modal h2 {
    margin: 1rem;
}

.admin-order-list .delete-modal .horizontal-rule {
    background: var(--color-dark);
    margin: 1rem 0;
    height: 1px;
    width: 80%;
}

.admin-order-list .delete-modal .buttons {
    margin: 1rem;
}

.admin-order-list .delete-modal .buttons button {
    margin: 0 1rem;
    padding: .4rem .8rem;
    border: none;
    background: var(--color-dark);
    color: var(--color-light);
    font-size: 1.2rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}