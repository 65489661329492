.weekly-menu {    
    position: relative;
    min-height: 100vh;
    padding: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-light);
    color: var(--color-dark);
    z-index: 3;
}

@media only screen and (max-width: 1210px) {
    .weekly-menu {
        padding: 10vh 1rem;
    }
}

.weekly-menu .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 0;
}

.weekly-menu .title {
    text-align: center;
    padding: 1rem 0;
}

.weekly-menu .week {
    position: relative;
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.weekly-menu .week .day {
    width: 30%;
    min-height: 280px;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: inset 0px 80px 100px rgba(0,0,0,0.1), 0px 2px 10px 0px rgba(0,0,0,0.3);
    background: var(--color-light);
    border: 15px solid var(--color-light);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.weekly-menu .week .day:nth-child(1) {
    border-color: var(--color-palette-1);
}
.weekly-menu .week .day:nth-child(2) {
    border-color: var(--color-palette-2);
}
.weekly-menu .week .day:nth-child(3) {
    border-color: var(--color-palette-3);
}
.weekly-menu .week .day:nth-child(4) {
    border-color: var(--color-palette-4);
}
.weekly-menu .week .day:nth-child(5) {
    border-color: var(--color-palette-3);
}
.weekly-menu .week .day:nth-child(6) {
    border-color: var(--color-palette-1);
}

@media only screen and (max-width: 1210px) {
    .weekly-menu .week .day {
        width: 100%;
    }
}

.weekly-menu .description {
    font-style: italic;
    font-size: 1.1rem;
}

.weekly-menu .price {
    font-weight: 600;
    padding: .5rem;
    border-top: 1px solid var(--color-dark);
}

/* .weekly-menu button {
    position: relative;
    cursor: pointer;
    padding: .4rem .8rem;
    border: none;
    background: var(--color-dark);
    color: var(--color-light);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
} */

/* Downloadable image */

.weekly-menu .download-menu {
    position: relative;
    margin: 1rem 0;
    width: 1500px;
    height: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
}

.weekly-menu .download-menu .logo {
    position: relative;
    margin: 1rem 0;
    height: 200px;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.weekly-menu .download-menu .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 0;
}

.weekly-menu .download-menu .container {
    position: relative;
    margin: 0;
    width: 1500px;
    height: 1250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.weekly-menu .download-menu .day {
    width: 570px;
    height: 350px;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: var(--color-light-static);
    color: var(--color-dark-static);
    border: 15px solid var(--color-light);
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.weekly-menu .download-menu .day:nth-child(1) {
    border-color: var(--color-palette-1);
}
.weekly-menu .download-menu .day:nth-child(2) {
    border-color: var(--color-palette-2);
}
.weekly-menu .download-menu .day:nth-child(3) {
    border-color: var(--color-palette-3);
}
.weekly-menu .download-menu .day:nth-child(4) {
    border-color: var(--color-palette-4);
}
.weekly-menu .download-menu .day:nth-child(5) {
    border-color: var(--color-palette-3);
}
.weekly-menu .download-menu .day:nth-child(6) {
    border-color: var(--color-palette-1);
}

.weekly-menu .download-menu .price {
    border-top: 1px solid var(--color-dark-static);
}