.page-not-found {
    min-height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.page-not-found h1 {
    margin: 1rem 0;
    font-size: 4rem;
}

.page-not-found p {
    margin: 1rem 0;
    font-weight: 400;
    font-style: italic;
}

.page-not-found a {
    margin: 1rem 0;
    font-weight: 500;
    color: var(--color-palette-3);
    text-decoration: underline;
}

.page-not-found img {
    width: 20vw;
}

@media only screen and (max-width: 1210px) {
    .page-not-found img {
        width: 50vw;
    }
}