.news-item {
    position: relative;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-light);
}

.news-item img {
    max-width: 90%;
    height: 70vh;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    object-fit: contain;
}

.news-item .content {
    padding: 10px;
    text-align: center;
    color: var(--color-dark);
}

.news-item .content h3 {
    margin: 50px 0;
    font-weight: 300;
}

.news-item .content .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.news-item a i {
    margin-right: 10px;
}

.news-item .animation-container {
   height: 80vh;
   border-radius: 10px;
   -webkit-border-radius: 10px;
   -moz-border-radius: 10px;
   -ms-border-radius: 10px;
   -o-border-radius: 10px;
   overflow: hidden;
   box-shadow: 1px 1px 60px 20px rgba(var(--rgb-dark),.1);
}

.news-item .animation-container .animation {
    height: 100%;
}