.contact {
    min-height: 60vh;
    padding: 10vh 5vw;
    padding-bottom: 10vh;
    background: var(--color-light);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.contact .address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact .address a {
    width: 100%;
    margin: 1rem 0rem;
    font-size: 1.5rem;
    color: rgba(var(--rgb-dark-alt),0.5);
    
    cursor: pointer;
}

.contact .address a:hover {
    width: 100%;
    margin: 1rem 0rem;
    font-size: 1.5rem;
    color: rgba(var(--rgb-dark-alt),0.35);
    
    cursor: pointer;
}

.contact .address a i {
    margin-right: 1rem;
}

@media only screen and (max-width: 1210px) {
    .contact {
        flex-direction: column;
    }

    .contact .address a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .contact .address a i {
        margin-right: 0rem;
        margin: 1rem;
    }
}

.contact iframe {
    margin: 3rem 0rem;
    width: 100%;
    min-height: 400px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.1);
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media only screen and (min-width: 1210px) {
    .contact iframe {
        width: 50%;
    }
    .contact-page .contact iframe {
        width: 100%;
        min-height: 550px;
    }
    .contact-page .contact .address {
        width: 100%;
        flex-direction: row;
    }
    .contact-page .contact .address a {
        text-align: center;
    }
    .contact-page .contact .address h1 {
        display: none;
    }
}