.order-list {
    padding: 10vh;
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-list h1.title{
    color: var(--color-dark);
    text-align: center;
    margin: 2rem 0;
}

.order-list table {
    width: 80%;
}

@media only screen and (max-width: 1210px) {
    .order-list {
        padding: 10vh 1rem;
    }
    .order-list table {
        width: 100%;
    }
}

.order-list table td {
    padding: .5rem;
}

.order-list table tr:nth-child(odd) {
    background: rgba(var(--rgb-dark),0.2);
}

.order-list table .checkbox-container {
    width: 20px;
    height: 20px;
}

.order-list table .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-dark);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.order-list button {
    margin: 2rem 0;
    padding: .4rem .8rem;
    border: 1px solid var(--color-dark);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}