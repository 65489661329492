
.home .header {
    padding: 5rem 10vw;
    min-height: 90vh;
    min-width: 100%;
    display: grid;
    gap: 1.5rem;

}

@media only screen and (min-width: 1211px) {
    .home {
        padding: 0rem 10rem;
    }
    .home .header {
        padding: 10rem 10vw;
        grid-template-columns: repeat(4, 1fr);
    }
}

.home .header .item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    min-height: 30vh;

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-border-radius: 10px;

    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    background: var(--color-palette-3);
    color: var(--color-dark);

    transition: ease-in-out 0.1s;
    -webkit-transition: ease-in-out 0.1s;
    -moz-transition: ease-in-out 0.1s;
    -ms-transition: ease-in-out 0.1s;
    -o-transition: ease-in-out 0.1s;

    overflow: hidden;

    cursor: pointer;
}

.home .header .item .content {
    position: relative;
    z-index: 2;
}

.home .header .item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;

    z-index: 0;
}

.home .header .item:hover img {
    width: 110%;
    height: 110%;

    transition: ease-in-out 0.6s;
    -webkit-transition: ease-in-out 0.6s;
    -moz-transition: ease-in-out 0.6s;
    -ms-transition: ease-in-out 0.6s;
    -o-transition: ease-in-out 0.6s;

    filter: grayscale(70%);
    -webkit-filter: grayscale(70%);
}

@media only screen and (min-width: 1211px) {
    .home .header .item.grid-col-span-2 {
        grid-column: span 2;
    }
    .home .header .item:last-child {
        grid-column-start: 4;
        grid-row: 1 / 3;
    }
}

.home .header .item .item-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, var(--color-dark-static) 10%, rgba(var(--rgb-light-static),0) 50%);
    z-index: 1;
}

.home .header .item .content {
    position: absolute;
    bottom: 1rem;
    padding: 0rem 1rem;
    color: var(--color-light-static);
    text-align: center;
}