.about {
    display: flex;
    flex-direction: column-reverse;
    min-height: 80vh;
    padding: 0rem 1rem;
}

.about .text {
    padding: 1rem;
    color: var(--color-dark);
}

.about .text h1 {
    margin: 2rem 0rem;
}

.about .text p {
    font-size: 1.2rem;
    letter-spacing: 0.13rem;
    line-height: 2rem;
}

.about img {
    width: 100%;
    height: 80vh;
    object-fit: contain;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

@media (min-width: 1210px) {
    .about {
        display: flex;
        flex-direction: row;
        min-height: unset;
        min-height: 60vh;
    }
    .about .text {
        max-width: 60%;
    }
    .about .text p {
        font-size: 1.4rem;
        max-width: 80%;
    }
    .about img {
        max-width: 40%;
        height: 50vh;
        object-fit: cover;
        object-position: center 40%;
        border: 5px solid var(--color-light-static);
        border-bottom: 20px solid var(--color-light-static);
        transform: rotate(3deg);
        -webkit-transform: rotate(3deg);
        -moz-transform: rotate(3deg);
        -ms-transform: rotate(3deg);
        -o-transform: rotate(3deg);
        box-shadow: 0px 2px 10px rgba(var(--rgb-dark-static),0.5);
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
}   
}