.home {
    min-height: 100vh;
    width: 100vw;
    background: var(--color-light);
    color: var(--color-dark);
    overflow: hidden;
}

.home .news-flash {
    margin-bottom: 30px;
    padding: 20px 100px;
    border-bottom: 1px solid var(--color-dark);
}

@media only screen and (max-width: 1200px) {
    .home .news-flash {   
        padding: 20px;
    }
}

.home .news-flash h1 {
    margin-bottom: 40px;
    text-align: center;
}

.home .news-flash p {
    opacity: .8;
    font-size: 1.2rem;
}

.home .news-flash p span {
    font-family: 'Caveat', cursive;
    font-size: 2rem;
}