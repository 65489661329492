:root {
  --color-dark-static: rgba(22,22,22,1.0);
  --color-light-static: rgba(233,233,233,1.0);

  --rgb-dark-static: 22,22,22;
  --rgb-light-static: 233,233,233;
  --brand-font: "Men-Nefer";
}

[data-theme='light'] {
  --color-dark: rgba(22,22,22,1.0);
  --color-light: rgba(233,233,233,1.0);

  --rgb-dark: 22,22,22;
  --rgb-light: 233,233,233;

  --color-palette-1: rgba(202,255,179,1.0);
  --color-palette-2: rgba(174,190,95,1.0);
  --color-palette-3: rgba(81,185,151,1.0);
  --color-palette-4: rgba(154,223,136,1.0);

  --rgb-palette-1: 202,255,179;
  --rgb-palette-2: 174,190,95;
  --rgb-palette-3: 81,185,151;
  --rgb-palette-4: 154,223,136;
}

[data-theme='dark'] {
  --color-dark: rgba(233,233,233,1.0);
  --color-light: rgba(22,22,22,1.0);

  --rgb-dark: 233,233,233;
  --rgb-light: 22,22,22;

  --color-palette-1: rgba(65,82,58,1.0);
  --color-palette-2: rgba(88,96,50,1.0);
  --color-palette-3: rgba(39,116,90,1.0);
  --color-palette-4: rgba(88,128,78,1.0);

  --rgb-palette-1: 65,82,58;
  --rgb-palette-2: 88,96,50;
  --rgb-palette-3: 39,116,90;
  --rgb-palette-4: 88,128,78;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Anybody', cursive;
  font-family: 'Roboto', sans-serif;
}

body,
html {
  overflow-x: hidden;
}

.App {
  transition: ease-in-out 0.2s;
  -webkit-transition: ease-in-out 0.2s;
  -moz-transition: ease-in-out 0.2s;
  -ms-transition: ease-in-out 0.2s;
  -o-transition: ease-in-out 0.2s;
}

@font-face {
  font-family: "Men-Nefer";
  src: local("MenNefer"),
   url("./assets/fonts/men-nefer/Men-Nefer.ttf") format("truetype");
  font-weight: bold;
}

.brand-font {
  font-family: "Men-Nefer";
}

button.primary {
  margin: 2rem .5rem;
  color: var(--color-light-static);
  background: var(--color-palette-3);
  border: 2px solid var(--color-palette-3);
  padding: .8rem 1.6rem;
  font-weight: 700;
  letter-spacing: .15rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

button.primary:hover {
  background: rgba(var(--rgb-palette-3),.8);
  border: 2px solid transparent;
}

button.secondary {
  margin: 2rem .5rem;
  color: var(--color-dark);
  background: transparent;
  border: 2px solid var(--color-dark);
  padding: .8rem 1.6rem;
  font-weight: 700;
  letter-spacing: .15rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

button.secondary:hover {
  color: var(--color-palette-2);
  border: 2px solid var(--color-palette-2);
}

a {
  text-decoration: none;
}

a.button {
  margin: 2rem 0;
  color: var(--color-light);
  background: var(--color-dark);
  padding: .8rem 1.6rem;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

a.button:hover {
  color: var(--color-light-static);
  background: var(--color-palette-3);
}

.link-button {
  margin: 2rem 0;
  color: var(--color-light);
  background: var(--color-dark);
  padding: .8rem 1.6rem;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

.link-button:hover {
  color: var(--color-light-static);
  background: var(--color-palette-3);
}

.modal.hidden,
.modal-overlay.hidden {
    display: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(var(--rgb-dark-static),0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 98;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 40%;
  padding: 2rem;
  background: var(--color-light);
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.2);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  z-index: 99;
}

.close-modal-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.7rem;
  cursor: pointer;
}