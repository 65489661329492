.edit-order-list {
    min-height: 100vh;
    padding: 10vh;
    background: var(--color-light);
}

.edit-order-list h1.title {
    color: var(--color-dark);
    text-align: center;
}

/* item example */
.edit-order-list .item-example {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-dark);
}

.edit-order-list .item-example i {
    margin: 0 .5rem;
    font-size: 2rem;
}

.edit-order-list .item-example p {
    margin: 0 .2rem;
    padding: 0.4rem 0.8rem;
    border: 1px solid var(--color-dark);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

/* item example array */
.edit-order-list .item-example-array {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    color: var(--color-dark);
}


.edit-order-list .item-example-array p {
    position: relative;
    margin: .2rem;
    padding: 0.5rem 1rem;
    padding-right: 1.8rem;
    border: 1px solid var(--color-dark);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: default;
}

.edit-order-list .item-example-array p i {
    position: absolute;
    top: 0;
    right: 0;
    margin: .2rem .4rem;
    font-size: 1.1rem;
    cursor: pointer;
}

/* form */
.edit-order-list .form {
    padding: 1rem;
}

.edit-order-list .form .form-section {
    margin: 1.2rem 0;
    display: flex;
    flex-direction: column;
}

.edit-order-list .form .form-section label {
    font-size: 1.3rem;
    color: var(--color-dark);
    margin: .5rem 0;
}

.edit-order-list .form .form-section input,
.edit-order-list .form .form-section select {
    height: 40px;
    font-size: 19px;
    padding: 0 1rem;
    border: 1px solid var(--color-dark);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}

.edit-order-list .form .form-section input {
    padding: 1rem;
}

/* buttons */
.edit-order-list .buttons {
    width: 400px;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}


.edit-order-list .buttons button {
    cursor: pointer;
}

.edit-order-list .buttons button.disabled {
    opacity: 0.4;
    cursor: default;
}

/* buttons page */

.edit-order-list .buttons .page {
    position: relative;
    padding: 1rem 0;
    width: 150px;
    height: 80px;
}

.edit-order-list .buttons .page button {
    width: 40px;
    height: 40px;
    border: none;
    background: var(--color-dark);
    color: var(--color-light);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.edit-order-list .buttons .page .left {
    position: absolute;
    left: 0;
    margin: 0 1rem;
}
.edit-order-list .buttons .page .right {
    position: absolute;
    right: 0;
    margin: 0 1rem;
}

/* buttons wrap up */
.edit-order-list .buttons .wrap-up {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.edit-order-list .buttons .wrap-up button {
    width: 100%;
    margin: .5rem .5rem;
    padding: .3rem .6rem;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 1rem;
    color: var(--color-dark);
}

.edit-order-list .buttons .wrap-up button.save {
    background: var(--color-light);
    border: 1px solid var(--color-dark);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.edit-order-list .buttons .wrap-up button.add {
    border-bottom: 1px solid var(--color-dark);
}

