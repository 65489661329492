.add-weekly-menu {
    padding: 10vh;
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1210px) {
    .add-weekly-menu {
        padding: 10vh 1rem;
    }
}

.add-weekly-menu .form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.add-weekly-menu .form .section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-weekly-menu .form .section .day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.add-weekly-menu .form .section .day h1 {
    position: relative;
    margin: 0.2rem 1rem;
    width: 230px;
    z-index: 3;
}

.add-weekly-menu .form .section .day h1 span {
    font-size: .9rem;
}

.add-weekly-menu .form .section .day input[type='checkbox'] {
    height: 20px;
    width: 20px;
}

.add-weekly-menu .form .section .input-section {
    width: 100%;
    margin: .3rem;
}

.add-weekly-menu .form .section .inputs {
    width: 100%;
    opacity: 1;
    transition: ease-out 0.2s;
    -webkit-transition: ease-out 0.2s;
    -moz-transition: ease-out 0.2s;
    -ms-transition: ease-out 0.2s;
    -o-transition: ease-out 0.2s;
}

.add-weekly-menu .form .section .inputs.hidden {
    display: none;
}

.add-weekly-menu .form .section .input-section label {
    font-size: 1.3rem;
}
.add-weekly-menu .form .section .input-section input {
    width: 100%;
    height: 40px;
    padding: 1rem;
    font-size: 19px;
    border: 1px solid var(--color-dark-static);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


/* Checkbox toggle */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}
  
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: var(--color-light);
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--color-palette-3);
}
  
input:focus + .slider {
    box-shadow: 0 0 1px var(--color-palette-3);
}
  
input:checked + .slider:before {
    transform: translateX(13px);
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    -moz-transform: translateX(13px);
    -o-transform: translateX(13px);
}
  
.slider.round {
    border-radius: 17px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    -ms-border-radius: 17px;
    -o-border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}

.add-weekly-menu .loading-spinner {
    position: absolute;
    top: 50%;
    width: 50px;
}

.add-weekly-menu .minimize-icon {
    font-size: 1.7rem;
    margin-left: 1.5rem;
    cursor: pointer;
}

.add-weekly-menu .minimize-icon.hidden {
    display: none;
}

/* MODAL */

.add-weekly-menu .check-modal .daily-menu {
    margin: 1rem;
    width: 100%;
}

.add-weekly-menu .check-modal .daily-menu .check-day {
    color: var(--color-palette-3);
}

.add-weekly-menu .check-modal .daily-menu p {
    margin-right: 12px;
}

