.admin-hub {
    padding: 10vh;
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-hub a {
    min-width: 30%;
    margin: 1rem 0;
    padding: .4rem .8rem;
    font-size: 1.5rem;
    color: var(--color-dark);
    border: 3px solid var(--color-dark);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
}

.admin-hub a:hover {
    color: var(--color-light-static);
    background: var(--color-palette-3);
    border: 3px solid var(--color-palette-3);
}

.admin-hub a.disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.admin-hub a.disabled:hover {
    color: var(--color-dark);
    background: none;
    border: 3px solid var(--color-dark);
}