/* Navbar */

.navbar {
    position: fixed;
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--color-light);
    color: var(--color-dark);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    z-index: 99;
}

.navbar ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
}

.navbar ul a {
    font-size: 1.2rem;
    margin: 1.5rem 1rem;
    cursor: pointer;
    color: var(--color-dark);
    border-bottom: 3px solid transparent;
    transition: border-bottom ease-in-out 0.3s;
    -webkit-transition: border-bottom ease-in-out 0.3s;
    -moz-transition: border-bottom ease-in-out 0.3s;
    -ms-transition: border-bottom ease-in-out 0.3s;
    -o-transition: border-bottom ease-in-out 0.3s;
}

.navbar ul a:hover {
    border-bottom: 3px solid var(--color-palette-3);
    transition: border-bottom ease-in-out 0.1s;
    -webkit-transition: border-bottom ease-in-out 0.1s;
    -moz-transition: border-bottom ease-in-out 0.1s;
    -ms-transition: border-bottom ease-in-out 0.1s;
    -o-transition: border-bottom ease-in-out 0.1s;
}

.navbar ul a.active {
    border-bottom: 3px solid var(--color-palette-4);
}

.navbar .theme-toggle {
    position: absolute;
    font-size: 1.7rem;
    top: 50%;
    right: 3rem;

    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    cursor: pointer;
}

.download-button {
    padding: 6px 12px;
    background-color: var(--color-palette-3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

@media only screen and (max-width: 1210px) {
    .navbar {
        display: none;
    }
}

/* Navbar Small */

.navbar-small {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: var(--color-light);
    color: var(--color-dark);
    z-index: 98;
    transition: ease-in-out 0.1s;
    -webkit-transition: ease-in-out 0.1s;
    -moz-transition: ease-in-out 0.1s;
    -ms-transition: ease-in-out 0.1s;
    -o-transition: ease-in-out 0.1s;
}

.navbar-small.active {
    transform: translateY(0vh);
    -webkit-transform: translateY(0vh);
    -moz-transform: translateY(0vh);
    -ms-transform: translateY(0vh);
    -o-transform: translateY(0vh);
}

.navbar-small.inactive {
    transform: translateY(100vh);
    -webkit-transform: translateY(100vh);
    -moz-transform: translateY(100vh);
    -ms-transform: translateY(100vh);
    -o-transform: translateY(100vh);
}

.navbar-small ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navbar-small ul a {
    list-style-type: none;
    margin: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: var(--color-dark);
}

.navbar-small .theme-toggle {
    margin-top: 2rem;
}

.navbar-small .download-button {
    max-width: 70%;
}

@media only screen and (min-width: 1211px) {
    .navbar-small {
        display: none;
    }
}

/* Navbar Small Toggle */

.navbar-toggle {
    position: fixed;
    left: 50%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: var(--color-light);
    color: var(--color-dark);

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;

    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);

    transition: ease-in-out 0.1s, background ease-in-out 0.0s;
    -webkit-transition: ease-in-out 0.1s, background ease-in-out 0.0s;
    -moz-transition: ease-in-out 0.1s, background ease-in-out 0.0s;
    -ms-transition: ease-in-out 0.1s, background ease-in-out 0.0s;
    -o-transition: ease-in-out 0.1s, background ease-in-out 0.0s;
    
    cursor: pointer;
    z-index: 99;
}

.navbar-toggle.active {
    bottom: 5%;
    border: none;
    font-size: 2rem;
}

.navbar-toggle.inactive {
    bottom: 3%;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
}

@media only screen and (min-width: 1211px) {
    .navbar-toggle {
        display: none;
    }
}

.logo-text {
    position: absolute;
    font-size: 2rem;
    letter-spacing: 0.2rem;
    color: var(--color-dark);
}

.logo-text small {
    font-size: 0.8rem;
    color: rgba(var(--rgb-dark),0.7);
}

.logo-text .logged-in-as {
    margin: .2rem 0;
    font-size: .7rem;
    font-style: italic;
    color: rgba(var(--rgb-dark),0.5);
}

.logo-mobile-top {
    position: fixed;
    top: 0;
    height: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: var(--color-light);
    color: var(--color-dark);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    z-index: 99;
}

@media only screen and (min-width: 1211px) {
    .logo-text {
        top: 50%;
        left: 3rem;
        
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }
    .logo-mobile-top {
        display: none;
    }
}


