.about-page {
    padding: 0vh 1vw;
    padding-bottom: 10vh;
    min-height: 110vh;
    background: var(--color-light);
}

@media only screen and (min-width: 1210px) {
    .about-page {
        padding: 12vh 10vw;
        padding-bottom: 0vh;
    }
}