.google-reviews {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 2rem;
}

@media only screen and (max-width: 1210px){
    .google-reviews {
        padding: 0rem;
    }
}

.google-reviews .google-reviews_title {
    font-size: 1.5rem;
    font-family: var(--brand-font);
}

.google-reviews .google-reviews_title.loading,
.google-reviews .google-reviews_subtitle.loading {
    color: transparent;
    text-shadow: 0px 0px 8px var(--color-dark);
}

.google-reviews .google-reviews_title.loading span,
.google-reviews .google-reviews_subtitle.loading span {
    color: transparent;
    text-shadow: 0px 0px 8px var(--color-palette-3);
}

.google-reviews .google-reviews_subtitle {
    font-size: 0.9rem;
    margin: 1rem;
    font-style: italic;
}

.google-reviews .google-reviews_title span {
    color: var(--color-palette-3);
}

.google-reviews .google-reviews_subtitle span {
    color: var(--color-palette-3);
}

.list-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.google-reviews .list-container .list {
    position: relative;
    left: 0;
    display: flex;
    padding: 0rem 1rem;
}

.google-reviews .list-container .list .review {
    width: 350px;
    height: 400px;
    margin: 1rem;
    padding: 1rem 2rem;
    background: var(--color-light);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3), inset 0px 0px 5rem 4rem rgba(0,0,0,0.1);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: flex-start;
    scroll-snap-align: center;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.google-reviews .list-container .list .review.loading {
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1210px) {
    .google-reviews .list-container .list .review {
        width: 80vw;
        height: 450px;
    }
}

.google-reviews .list-container .list .review img:not(.loading-spinner) {
    width: 70px;
    height: 70px;
    border: 3px solid var(--color-palette-3);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.google-reviews .list-container .list .review img.loading-spinner {
    width: 60px;
    height: 60px;
}

.google-reviews .list-container .list .review h1 {
    font-family: var(--brand-font);
    margin: 10px;
    font-size: 1.2rem;
}

.google-reviews .list-container .list .review p {
    font-size: 0.9rem;
    line-height: 1.4rem;
}

.google-reviews .list-container .list .review a {
    position: absolute;
    bottom: 2rem;
    color: var(--color-palette-3);
    text-decoration: underline;
    cursor: pointer;
}

.google-reviews .list-container .list .review-rating {
    position: absolute;
    bottom: 4rem;
}

.google-reviews .list-container .list .review-rating i {
    margin: 0rem 5px;
}

.google-reviews .list-container .list .review-rating i.filled {
    color: rgb(226, 208, 49);
}

/* width */
.google-reviews ::-webkit-scrollbar {
    height: 20px;
}

/* Track */
.google-reviews ::-webkit-scrollbar-track {
    background: var(--color-light);
}

/* Handle */
.google-reviews ::-webkit-scrollbar-thumb {
    background: var(--color-palette-3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

/* Handle on hover */
.google-reviews ::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--rgb-palette-3),0.7);
}