.admin-weekly-menu {
    padding: 10vh;
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-weekly-menu .current-dishes-container {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

.admin-weekly-menu .current-dishes {
    display: flex;
    justify-content: center;
}

.current-dishes .dish {
    height: 200px;
    width: 300px;
    margin: 1rem;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background: rgba(var(--rgb-light),1);
    color: var(--color-dark);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.3);
    border: 5px solid var(--color-palette-2);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.current-dishes .dish.today {
    height: 220px;
    width: 330px;
    border: 5px solid var(--color-palette-3);
}

.current-dishes .dish.no-menu {
    opacity: 0.5;
}

.current-dishes .dish h1 {
    font-size: 1rem;
}

.current-dishes .dish p.today {
    color: var(--color-palette-3);
    font-weight: 600;
}

.current-dishes .dish p.not-today {
    color: var(--color-dark);
    font-weight: 300;
}

.current-dishes .dish p.price {
    font-weight: 600;
}

@media (max-width: 1210px) {
    .admin-weekly-menu {
        padding: 10vh 0rem;
    }
    .admin-weekly-menu .current-dishes-container {
        align-items: flex-start;
        padding: 2rem 0;
    }
}

.current-dishes-container .buttons {
    width: 100%;
    position: absolute;
    bottom: 5rem;
    display: flex;
    justify-content: center;
    text-align: center;
}

.current-dishes-container .buttons a {
    margin: 0 1rem;
}

@media (max-width: 1210px) {
    .current-dishes-container .buttons {
        flex-direction: column;
        align-items: center;
    }
    .current-dishes-container .buttons a {
        width: 80%;
        margin: 1rem 0;
    }
}

/* scrollbar */

.admin-weekly-menu ::-webkit-scrollbar {
    height: 20px;
}

.admin-weekly-menu ::-webkit-scrollbar-track {
    background: var(--color-light);
}

.admin-weekly-menu ::-webkit-scrollbar-thumb {
    background: var(--color-palette-3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.admin-weekly-menu ::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--rgb-palette-3),0.7);
}