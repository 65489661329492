.menu-page {
    min-height: 110vh;
    padding: 10vh 0;
    background: var(--color-light);
    color: var(--color-dark);
}

@media only screen and (min-width: 1211px){
    .menu-page {
        padding: 10vh 10vw;
    }
}