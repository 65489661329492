.login {
    min-height: 100vh;
    background: var(--color-light);
    color: var(--color-dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .form {
    height: 50%;
    min-width: 40%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: var(--color-light);
    border: 3px solid var(--color-palette-3);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.login .form .form-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0;
}

.login .form .form-section label {
    width: 100%;
    text-align: left;
    margin: .5rem 0;
}

.login .form .form-section input {
    height: 40px;
    padding: 1rem;
    font-size: 19px;
    border: 1px solid var(--color-dark-static);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.login .form .form-section .password-input {
    display: flex;
    width: 100%;
}

.login .form .form-section .password-input input {
    width: 95%;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.login .form .form-section .password-input i {
    height: 40px;
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-light-static);
    color: var(--color-dark-static);
    border: 1px solid var(--color-dark-static);
    border-left: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
}

.login .form .horizontal-rule {
    height: 1px;
    width: 80%;
    background: var(--color-palette-3);
    margin: 1rem;
}

.login .form button {
    min-width: 30%;
    font-size: 1rem;
    margin: .5rem 0;
    padding: .4rem .8rem;
    border: none;
    background: none;
    cursor: pointer;
}

.login .form button.primary {
    background: var(--color-dark);
    color: var(--color-light);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.login .form button.secondary {
    color: var(--color-palette-2);
}

@media only screen and (max-width: 1210px) {
    .login .form {
        min-width: 90%;
    }
    .login .form .form-section .password-input input {
        width: 90%;
    }
    
    .login .form .form-section .password-input i {
        width: 10%;
    }
}
