.footer {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 17vh;
    background: var(--color-light);
}

@media only screen and (min-width: 1210px) {
    .footer {
        flex-direction: row;
        justify-content: space-around;
        box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.3);
        padding-bottom: 0;
    }
}

.footer a {
    color: var(--color-palette-3);
}

.footer a:hover {
    color: var(--color-palette-2);
}

.footer img {
    width: 140px;
    height: 140px;
    margin: 2rem 0rem;
}

.footer .footer-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.footer .footer-menu a {
    margin: 0.3rem 0rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: underline;
}

.footer .social-media {
    width: 100%;
    text-align: center;
}

.footer .social-media a {
    margin: 1rem;
    font-size: 2.5rem;
}

@media only screen and (min-width: 1210px) {
    .footer .social-media {
        width: 200px;
    }
    .footer img {
        margin: 0;
    }
    .footer .footer-menu {
        width: 200px;
        text-align: left;
    }
}