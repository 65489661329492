.alert {
    position: fixed;
    top: 5.5rem;
    right: .5rem;
    min-width: 220px;
    max-width: 330px;
    min-height: 100px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: var(--color-light);
    font-weight: 500;
    background: var(--color-dark);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.3);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: ease-out 0.2s;
    -webkit-transition: ease-out 0.2s;
    -moz-transition: ease-out 0.2s;
    -ms-transition: ease-out 0.2s;
    -o-transition: ease-out 0.2s;
    overflow: hidden;
}

.alert .close {
    position: absolute;
    top: .3rem;
    right: .6rem;
    font-size: 1.2rem;
    color: var(--color-light);
    cursor: pointer;
}

.alert.hidden {
    transform: translateX(120%);
    -webkit-transform: translateX(120%);
    -moz-transform: translateX(120%);
    -ms-transform: translateX(120%);
    -o-transform: translateX(120%);
}

.alert .time-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100px;
    background: var(--color-palette-3);
}