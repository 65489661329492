.menu-container {
    padding: 5vh 0;
}

.menu-container h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (min-width: 1210px) {
    .menu {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .menu-container {
        padding: 5vh 3vw;
    }
}

.menu .menu-section {
    width: 50%;
    padding: 2rem 1rem;
    font-size: 1.4rem;
    color: var(--color-dark);
}

@media only screen and (max-width: 1210px) {
    .menu .menu-section {
        width: 90%;
    }
}

.menu .menu-section .menu-item {
    display: table-row-group;
}

.menu .menu-section h1 {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    background: var(--color-palette-4);
    margin: 1rem 0rem;
    padding: 1rem 0rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.menu table td {
    width: 100%;
    padding-top: 1.4rem;
    font-weight: 700;
    border-bottom: 1px solid rgba(var(--rgb-dark),0.4);
}

.menu table .item-description {
    font-size: 0.9rem;
    font-style: italic;
}